import React,{useState} from "react";
import CustomButtonOval from '../../buttons/CustomButtonOval';
import SaltarPaso from "../SaltarPaso";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { update_usuario } from "../../../store/users/registro/action";
import InformacionPaciente from "./InformacionPaciente";
import InteresesPersonales from "../InteresesPersonales";
import Patologia from "./Patologia";
import Calendarizado from "./Calendarizado";
import { getAuthUser, pacientesResource } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import { showFormErrors } from "../../../helpers/showErrors";
import { validaCurp, validaDates, validaNames, validaNumerosTelefonos } from "../../../helpers/formularios/Validation";
import CancelarModal from "../CancelarModal";

const PerfilPaciente = ({ setStep, item, setItem }) => {
    const [modal, setModal] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = getAuthUser();
    const validationPatologia = (input) => {
        if (validation.values.patologia) {
            return input == "str" ? Yup.string().nullable() : validaNumerosTelefonos(false);
        } else {
            return Yup.string().nullable();
        }
    }
    const oneDayBefore = new Date();
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: authUser?.id ? item : item.paciente,
        validationSchema: Yup.object({
            perfilImg: Yup.string().required('Campo requerido'),
            nombre: validaNames(),
            apellidos: validaNames(),
            curp: validaCurp(),
            fechaNacimiento: validaDates(0),
            email: Yup.string()
                .nullable()
                .email('Correo electrónico inválido'),
            estadoCivil: Yup.string().nullable(),
            calle: Yup.string().required('Campo requerido'),
            cp: Yup.string().required('Campo requerido'),
            cp_id: Yup.string().required('Campo requerido'),
            numeroExterior: Yup.string().required('Campo requerido'),
            numeroInterior: Yup.string().required('Campo requerido, En caso de no contar con Numero Interior poner lo mismo que numero exterior.'),
            pais: Yup.string().required('Campo requerido'),
            estado: Yup.string().required('Campo requerido'),
            municipio: Yup.string().required('Campo requerido'),
            colonia: Yup.string().required('Campo requerido'),
            telefono: validaNumerosTelefonos(false),
            descripcionPersonal: Yup.string(),
            informacionUnica: Yup.string(),
            sexo_id: Yup.string().required('Campo requerido'), 
            patologia: Yup.boolean().required('Campo requerido'),
            diagnostico: Yup.lazy(value => {
                if (validation.values.patologia) {
                    return Yup.string().required('Campo requerido');
                } else {
                    return Yup.string().nullable();
                }
            }),
            condiciones_especiales: Yup.lazy(value => {
                return validationPatologia('str')
            }),
            medico_movil: Yup.lazy(value => {
                return validationPatologia('number')
            }),
            medico_telefono: Yup.lazy(value => {
                return validationPatologia('number')
            }),
            especialidad_id: Yup.number().required('Campo requerido'),
            alergias: Yup.string().nullable(),
            informacion_adicional: Yup.string().nullable(),
            turno_id: Yup.number().required('Campo requerido'),
            tiempo_id: Yup.number().required('Campo requerido'),
            fecha_inicio: Yup.date().required('Campo requerido').min(oneDayBefore, 'La fecha debe ser actual o futura'),
            fecha_fin: Yup.date()
                .required('Campo requerido')
                .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                    const fechaInicio = this.parent.fecha_inicio;
                    return fechaInicio < value;
                }),
            dias: Yup.array().of(Yup.number()).required('Campo requerido'),

        }),
        onSubmit: async (values, { setErrors }) => {
            if (authUser?.id) {
                const response = await pacientesResource(values.id ? 'put' : 'post', values, values.id);
                if (response.status) {
                    navigate('/perfiles')
                }
            } else {
                let newItem = { ...item, paciente: values }
                dispatch(update_usuario(newItem))
                window.scrollTo(0, 500);
                setStep(3)
            }

        },
    });



    return (
        <>
            {!authUser?.id &&
                <SaltarPaso
                    title={'Datos del paciente'}
                    text={'Crear un perfil paciente permite hacer búsquedas ágiles de profesionistas que pueden satisfacer las necesidades médicas de esa persona'}
                    textBold={'¡Es la forma más ágil de usar One 2 One Nurses!'}
                    step={4}
                    setStep={setStep}
                />
            }

            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                showFormErrors(validation);
                return false;
            }}
            >
                <InformacionPaciente validation={validation} />
                <InteresesPersonales validation={validation} categorias_ids={2} />
                <Patologia validation={validation} />
                <Calendarizado validation={validation} />
                {!authUser?.id ?
                    <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                        <button
                            className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                            onClick={() => setModal(true)}
                        >
                            Cancelar registro
                        </button>
                        <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                            Guardar información y continuar
                        </button>
                    </div>
                    :
                    <div className="bg-o2o-gris-calido flex justify-center items-center max-w-[85%] mx-auto rounded-lg p-[18px] pt-2 mt-12">
                        <div className="flex">
                            <CustomButtonOval
                                type={'success'}
                                className='bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover'
                            >
                                Guardar información
                            </CustomButtonOval>
                            <CustomButtonOval
                                type={'button'}
                                onClick={() => setModal(true)}
                                className='bg-o2o-gris-arena text-sm hover:bg-o2o-gris-arena-hover'
                            >
                                Cancelar
                            </CustomButtonOval>
                        </div>
                    </div>
                }

            </form >
            <CancelarModal modal={modal} setModal={setModal}/>
        </>
    )
}

export default PerfilPaciente