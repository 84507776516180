import React from "react";
import Home from "../pages/pagina-publica/home/Home";
import QuienesSomos from "../pages/pagina-publica/quienes-somos/QuienesSomos";
import Manifesto from "../pages/pagina-publica/manifesto/Manifesto";
import CartaDirectora from "../pages/pagina-publica/carta-directora/CartaDirectora";
import ComoFunciona from "../pages/pagina-publica/como-funciona/ComoFunciona";
import Seguridad from "../pages/pagina-publica/seguridad/Seguridad";
import CostosMenbresias from "../pages/pagina-publica/costos-membresias/CostosMembreias";
import Testimoniales from "../pages/pagina-publica/testimoniales/Testimoniales";
import Blog from "../pages/pagina-publica/blog/Blog";
import CentroAtencion from "../pages/pagina-publica/centro-atencion/CentroAtencion";
import DocumentacionInformacion from "../pages/pagina-publica/centro-atencion/DocumentacionInformacion";
import Login from "../pages/login/Login";
import Registro from "../pages/pagina-publica/registro/Registro";
import HomeLogin from "../pages/home/HomeLogin";
import Perfil from "../pages/perfiles/Perfil";
import Mensajes from "../pages/mensajes/Mensajes";
import AddUpdPaciente from "../pages/administrador/pacientes/AddUpdPaciente";
import BuscarEnfermero from "../pages/administrador/buscar/BuscarEnfermero";
import CancelacionPaciente from "../pages/administrador/pacientes/CancelacionPaciente";
//import Favoritos from "../pages/administrador/mis_favoritos/Favoritos";
import UnderConstruction from "../pages/UnderConstruction";
import Servicios from "../pages/servicios/Servicios";
import PerfilDetallado from "../pages/administrador/pacientes/PerfilDetalladoPaciente";
import PerfilDetalladoEnfermero from "../pages/enfermero/perfil/PerfilDetalladoEnfermero";
import Calificaciones from "../pages/servicios/calificaciones/Calificaciones";
import Turnos from "../pages/enfermero/turnos/Turnos";
import CalificacionPrimerdia from "../pages/servicios/calificaciones/CalificacionEnviada";
import AceptarSOlicitud from "../pages/servicios/aceptarYCancelarServicios/AceptarServicio";
import ServicioAceptado from "../pages/servicios/aceptarYCancelarServicios/ServicioAceptadoRechazado";
import FacturasEnfermero from "../pages/facturas/FacturasEnfermero";
import Honorarios from "../pages/honorarios/Honorarios";
import GestionarCuenta from "../pages/gestionar_cuenta/GestionarCuenta";
import BajaCuenta from "../pages/gestionar_cuenta/CancelarCuenta";
import ConfirmarBaja from "../pages/gestionar_cuenta/ConfirmacionBaja";
import InformacionCobro from "../pages/administrador/informacion_cobro/InformacionCobro";
import NuevoMetodoCobro from "../pages/administrador/informacion_cobro/NuevoMetodoCobro";
// Rutas logeadas
const userRoutes = [
    { path: '/home', element: <HomeLogin />, menu: 1 },
    { path: '/construccion', element: <UnderConstruction />, menu: 1 },
    { path: '/perfiles', element: <Perfil />, menu: 1 },
    { path: '/mensajes', element: <Mensajes />, menu: 1 },
    //{ path: '/favoritos', element: <Favoritos />, menu: 1 },
    { path: '/nuevo-paciente', element: <AddUpdPaciente />, menu: 1 },
    { path: '/editar-paciente/:id', element: <AddUpdPaciente />, menu: 1 },
    { path: '/baja-paciente/:id', element: <CancelacionPaciente />, menu: 1 },
    { path: '/buscar-enfermero/paciente/:id', element: <BuscarEnfermero />, menu: 2 },
    { path: '/servicios', element: <Servicios/>, menu: 1},
    { path: '/paciente-solicitud/:id/:id_servicio?', element: <PerfilDetallado/>, menu: 1},
    { path: '/enfermero-detallado/:id/:id_paciente?', element: <PerfilDetalladoEnfermero/>, menu: 2},
    { path: '/turnos/', element:<Turnos/>,menu:1},
    { path: '/servicios-calificacion-primerdia', element:<CalificacionPrimerdia/>,menu:1},

    { path: '/servicios-calificacion/:id/:id_cita?', element:<Calificaciones/>, menu: 1},
    { path: '/servicios-aceptar/:id', element:<AceptarSOlicitud/>, menu: 1},
    { path: '/servicio-aceptado', element:<ServicioAceptado/>, menu: 1},
    
    { path: '/factura-enfermero/:id_servicio', element:<FacturasEnfermero/>, menu: 1},
    { path: '/honorarios', element: <Honorarios/>, menu: 1},
    { path: '/gestionar-cuenta', element:<GestionarCuenta/>, menu: 1},
    { path: '/baja-cuenta', element: <BajaCuenta/>, menu: 1},
    { path: 'confirmar-baja', element: <ConfirmarBaja/>, menu:1},
    
    { path: '/informacion-cobro', element: <InformacionCobro/>, menu:1},
    { path: '/metodo-pago/:id?', element: <NuevoMetodoCobro/>, menu:1},
    
];

// Rutas sin logear privadas... Estas rutas no puedes entrar si estas logeado
const publicRoutes = [
    { path: '/login', element: <Login /> },
    { path: '/registro/:perfil_id', element: <Registro /> },
];

const outRoutes = [
    { path: '/logout', element: <></> },
];

// Rutas a las que se puede acceder sin importar si estas logeado o no
const generalRoutes = [
    { path: '/', element: <Home /> },
    { path: '/quienes-somos', element: <QuienesSomos /> },
    { path: '/manifesto', element: <Manifesto /> },
    { path: '/carta-de-la-directora', element: <CartaDirectora /> },
    { path: '/como-funciona', element: <ComoFunciona /> },
    { path: '/seguridad', element: <Seguridad /> },
    { path: '/costos-membresias', element: <CostosMenbresias /> },
    { path: '/testimoniales', element: <Testimoniales /> },
    { path: '/blog', element: <Blog /> },
    { path: '/central-atencion', element: <CentroAtencion /> },
    { path: '/documentacion-informacion', element: <DocumentacionInformacion /> },
    { path: '/construccion-public', element: <UnderConstruction />, menu: 1 },
];

export { generalRoutes, publicRoutes, userRoutes, outRoutes };
