import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { Link } from "react-router-dom";
import QrServicios from "../../../components/QrServicios";
import { FormatCurrency } from "../../../helpers/CurencyFormat";
import { imgUrl } from "../../../helpers/recursosurl";
import { getAuthUser } from "../../../helpers/configRoutes/backend_helper";

const ConfirmacionServicio = ({ servicio, setComponent, declinar }) => {
    const authUser = getAuthUser();
    const qrvalue = `url del back`;
    const { id, id_cita } = useParams();
    const navigate = useNavigate();
    return (
        <>

            <div className="flex flex-col items-start mt-[46px] mx-auto lg:ml-[19%] lg:max-w-[75%] px-4">
                <CustomButtonOval
                    className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover mb-[20px]"
                    onClick={() => setComponent(false)}
                >
                    <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                    >
                        <path
                            d="M5.80078 1L1.00078 5L5.80078 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Regresar a solicitudes de servicio
                </CustomButtonOval>

                {!declinar ? (
                    <>
                        <div className="border-0 rounded-lg p-4 mb-4 w-full lg:w-[574px]">
                            <div className="text-[32px] mb-[18px] font-semibold">
                                <h2>Has aceptado el servicio de {servicio.administrador.nombre} {servicio.administrador.apellidos} para {servicio.paciente.nombre} {servicio.paciente.apellidos}</h2>
                            </div>
                            <div className="text-base">
                                <p><strong>¡Felicidades! Has aceptado exitosamente el servicio de cuidados enfermeros a domicilio.</strong> Recuerda que a partir de este momento se inician todos los protocolos para la operación del mismo. Resuelve cualquier duda adicional en el <Link style={{ color: 'blue' }}>Centro de Atención</Link> o escríbenos directamente al ZenDesk.</p>
                            </div>
                            <div>
                                <p>Revisa la información resumida del servicio a continuación, la cual la encontrarás en Central de Información  Mis Servicios  Servicios Activos.</p>
                            </div>
                        </div>

                        <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full lg:w-[926px]">
                            <div className="flex flex-col lg:flex-row justify-between items-center">
                                <div className="text-base font-semibold lg:w-1/3">
                                    Servicio de de {servicio.administrador.nombre} {servicio.administrador.apellidos} para:
                                </div>
                                <div className="flex-1 flex justify-center lg:w-1/3">
                                    <p>{servicio.folio}</p>
                                </div>
                                <div className="flex justify-end lg:w-1/3">
                                    <CustomButtonOval  className="flex justify-end bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover">
                                        Cancelar servicio
                                    </CustomButtonOval>
                                </div>
                            </div>

                            <hr className="border-gray-300 my-4" />
                            <div className="flex flex-col lg:flex-row justify-between">
                                <div className="lg:w-full flex flex-col lg:flex-row">
                                    <div className="flex flex-col">
                                        <div className="bg-[#f1ece9] rounded-full w-[72px] h-[72px] flex items-center justify-center mr-5 mb-5 lg:mb-0">
                                            <img
                                                loading="lazy"
                                                srcSet={servicio.paciente?.perfilImg || `${imgUrl}/user.png`}
                                                className="rounded-full shrink-0 max-w-full aspect-square w-[72px] lg:w-[164px]"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm font-semibold mb-3 flex items-center">
                                            <p className="mr-2">{servicio.paciente?.nombre} {servicio.paciente?.apellidos}</p>
                                        </div>
                                        <div className="w-full mb-3 border-t border-o2o-gris-arena border-solid">
                                            <div className="text-sm font mb-[18px]">
                                                {servicio.paciente?.diagnosticoM}
                                            </div>
                                            <div className="text-sm font mb-2">
                                                Enfermero con especialidad en
                                            </div>
                                            <div>
                                                <button className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 bg-o2o-gris-arena-button text-sm mb-[18px]">{servicio.enfermero?.especialidad}</button>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <Link className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover" to={`/paciente-solicitud`}>Ver perfil completo</Link>
                                                <CustomButtonOval className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">Reportar perfil</CustomButtonOval>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:w-1/2 flex flex-col items-center lg:items-start lg:mr-4 mb-4 lg:mb-0">
                                    <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena border-solid">
                                        {authUser.perfil_id == 4 ? (
                                            <p>Fecha de tu proxima cita: {servicio.cita_proxima?.fecha_inicial}</p>
                                        ) : (
                                            <QrServicios value={qrvalue} />
                                        )}
                                    </div>
                                </div>
                                <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
                                    <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena border-solid">
                                        <div className="text-sm font-semibold">
                                            Periodo del Servicio
                                        </div>
                                        <div className="text-sm font">
                                            <p>{servicio.fecha_inicial} al {servicio.fecha_fin}</p>
                                            <p>Turno {servicio.tiempo?.nombre} {servicio.turno?.nombre}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="border-gray-300 my-4" />
                            <div className="flex flex-col lg:flex-row justify-between mt-4">
                                <div className="flex flex-col flex-grow items-center lg:items-start ml-[18px] mr-[12px]">
                                    <div className="text-sm font">Costo total del servicio</div>
                                    <div className="text-sm font">{FormatCurrency(servicio.pagos?.costo_total)}</div>
                                </div>
                                <div className="flex flex-col flex-grow items-center lg:items-start mr-[8px]">
                                    <div className="text-sm font">Pago servicios One 2 One</div>
                                    <div className="text-sm font">{FormatCurrency(servicio.pagos?.comision)}</div>
                                </div>
                                <div className="flex flex-col flex-grow items-center lg:items-start lg:mr-[200px] mr-[12px]">
                                    <div className="text-base font-semibold">Tus honorarios</div>
                                    <div className="text-base font-semibold">{FormatCurrency(servicio.pagos?.sin_comision)}</div>
                                </div>
                            </div>
                        </div>

                        <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full lg:w-[926px]">
                            <div className="flex justify-start space-x-2">
                                <CustomButtonOval onClick={() => setComponent(false)} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                                    Aceptar. Ir a Servicios 
                                </CustomButtonOval>
                                <CustomButtonOval onClick={() => navigate('construccion')} className="flex justify-end bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover">
                                    Ir a Centro de Atención
                                </CustomButtonOval>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="border-0 rounded-lg p-4 mb-4 w-full lg:w-[547px]">
                        <div className="text-[32px] mb-[18px] font-semibold">
                            <h2>Has declinado el servicio de {servicio.administrador.nombre} {servicio.administrador.apellidos} para {servicio.paciente.nombre} {servicio.paciente.apellidos}</h2>
                        </div>
                        <div className="text-base mt-[30px]">
                            <p>Recibirás un correo electrónico con la confirmación de la declinación de la solicitud de servicio y su información desaparecerá de tu Central de Información. Esperamos pronto colaborar en el siguiente servicio.</p>
                        </div>
                        <div className="border rounded-lg p-4 mb-4 mt-[24px] bg-o2o-gris-calido-hover w-full lg:w-[926px]">
                            <div className="flex justify-start space-x-2">
                                <CustomButtonOval onClick={() => setComponent(false)} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                                    Aceptar. Ir a Mi Resumen
                                </CustomButtonOval>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ConfirmacionServicio;