import React, { useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import InformacionCuenta from "./InformacionCuenta";
import Divider from "../Divider";
import DatoPerfil from "./DatoPerfil";
import InteresesPersonales from "./InteresesPersonales";
import { update_usuario } from "../../store/users/registro/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showFormErrors } from "../../helpers/showErrors";
import { validaCurp,validaNames,validaDates,validaNumerosTelefonos } from "../../helpers/formularios/Validation";
import CancelarModal from "./CancelarModal";


const Perfil = ({ setStep, item, setItem }) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false)

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item,
        validationSchema: Yup.object({
            nombre: validaNames(),
            apellidos: validaNames(),
            fechaNacimiento: validaDates(18),
            movil: validaNumerosTelefonos(true),
            telefono: validaNumerosTelefonos(false),
            email: Yup.string()
                .email('Correo electrónico inválido')
                .required('Campo requerido'),
            contrasena: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,}$/,
                    'La contraseña debe contener al menos una letra mayúscula, un carácter especial, un número, y tener una longitud mínima de 8 caracteres'
                )
                .required('Campo requerido'),
            confirmacionContrasena: Yup.string()
                .oneOf([Yup.ref('contrasena'), null], 'Las contraseñas deben coincidir')
                .required('Campo requerido'),
            curp: validaCurp(),
            calle: Yup.string().required('Campo requerido'),
            cp: Yup.string()
                .required('Campo requerido'),
            numeroExterior: Yup.string().required('Campo requerido'),
            numeroInterior: Yup.string().required('Campo requerido, En caso de no contar con Numero Interior poner lo mismo que numero exterior.'),
            pais: Yup.string().required('Campo requerido'),
            estado: Yup.string().required('Campo requerido'),
            municipio: Yup.string().required('Campo requerido'),
            colonia: Yup.string().required('Campo requerido'),
            descripcionPersonal: Yup.string(),
            informacionUnica: Yup.string(),
            perfilImg: Yup.string().required('Campo requerido'),
            sexo_id: Yup.string().required('Campo requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            dispatch(update_usuario(values))
            window.scrollTo(0, 500);
            setStep(2)
        },
    });

    return (
        <>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}
            >
                <InformacionCuenta validation={validation} />
                <DatoPerfil validation={validation} />
                <InteresesPersonales validation={validation} categorias_ids={1} />
                <Divider />
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10">
                    <button
                    type="button"
                        className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Cancelar registro
                    </button>
                    <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                        Guardar información y continuar
                    </button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal}/>
        </>
    )
}

Perfil.propTypes = {
    setStep: PropTypes.func,
    data: PropTypes.func,
    setData: PropTypes.func,
    resetData: PropTypes.func,
}

export default Perfil