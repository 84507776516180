import { useNavigate } from 'react-router-dom'
import { imgUrl } from '../../../helpers/recursosurl'
import React from 'react'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'

const HeadHunters = () => {

    const navigate = useNavigate();

    return (
        <div className='flex flex-col mt-14 mb-[74px] max-w-[1042px] mx-auto px-4'>
            <div className='flex flex-col mt-14 mb-[74px] max-w-[1042px] mx-auto px-4'>
                <div className="flex flex-wrap justify-center font-bold mb-[60px]">
                    <div className="lg:w-2/3 w-full text-center mb-[18px]">
                        <h1 className="text-2xl lg:text-3xl">Headhunters para tu empresa</h1>
                    </div>
                    <div className="lg:w-2/3 w-full text-center mb-[18px]">
                        <p className="text-base lg:text-lg mb-[16px]">
                            <strong>Hospitales, asilos, centros de salud, empresas, entre otros.</strong>&nbsp;
                            Podemos ofrecerte uno a uno o varios especialistas para las necesidades específicas de tu institución.
                        </p>
                    </div>
                    <div className="lg:w-2/3 w-full text-center flex flex-wrap justify-center">
                        <CustomButtonOval
                            onClick={() => window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')}
                            className="bg-o2o-gris hover:bg-o2o-gris-hover w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4"
                        >
                            <p className="font-bold text-sm">Contacta a un ejecutivo</p>
                        </CustomButtonOval>
                        <CustomButtonOval
                            className="bg-o2o-gris hover:bg-o2o-gris-hover w-full lg:w-auto"
                            onClick={() => navigate('/costos-membresias')}
                        >
                            <p className='font-bold text-sm'>Conoce nuestras membresías</p>
                        </CustomButtonOval>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between w-full items-center md:space-x-8">
                    <div className="w-full md:w-1/4 mb-4 md:mb-0">
                        <img src={`${imgUrl}/hospital-angeles.png`} alt="Hospital Angeles" className="w-full h-auto" />
                    </div>
                    <div className="w-full md:w-1/4 mb-4 md:mb-0">
                        <img src={`${imgUrl}/hospital-puebla.png`} alt="Hospital Puebla" className="w-full h-auto" />
                    </div>
                    <div className="w-full md:w-1/4 mb-4 md:mb-0">
                        <img src={`${imgUrl}/hospital-beneficencia.png`} alt="Hospital Beneficencia" className="w-full h-auto" />
                    </div>
                    <div className="w-full md:w-1/4">
                        <img src={`${imgUrl}/50-doctors.png`} alt="" className="w-full h-auto" />
                    </div>
                </div>
            </div>

        </div>

    )
}

export default HeadHunters