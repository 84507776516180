import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { enfermeroTurnosResource, serviciosResource } from "../../helpers/configRoutes/backend_helper";


const ResumenEnfermero = ({ authUser }) => {
    const navigate = useNavigate();
    const sinLeer = useSelector((state) => state.mensajesContador.sin_leer);
    const [turnos, setTurnos] = useState([])
    const [filters, setFilters] = useState({ perPage: 5, page: 1, home:true })

    useEffect(() => {
        getEnfTurnos()
    }, []);

    const getEnfTurnos = async () => {
        const response = await enfermeroTurnosResource('get', {})
        setTurnos(response.turnos)
    }

    const [servicios, setServicios] = useState([])

    useEffect(() => {
        const getServicio = async () => {
            const response = await serviciosResource('get', { params: filters });
            setServicios(response.servicios);
        }
        getServicio();

    }, [filters]);

    return (
        <>
            <div className="flex flex-wrap max-w-[75%] ml-[19%] max-md:max-w-full max-md:ml-0">
                <div className="p-4">
                    <b className="block mb-2 text-xl">Esta es tu central de información.</b>
                    <p>Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                </div>
            </div>

            <div className="p-4 flex-wrap max-w-[75%] ml-[19%] max-md:max-w-full max-md:ml-0">
                <div className="flex items-center bg-o2o-gris-calido p-0 mt-4 rounded text-xl">
                    <span className="bg-o2o-morado text-white p-3 rounded text-xl">{sinLeer}</span>
                    <span className="ml-2 text-sm">Conversaciones sin leer</span>
                    <span className="ml-auto">
                        <CustomButtonOval
                            className="bg-o2o-gris hover:bg-o2o-aqua-hover text-sm"
                            onClick={() => navigate('/mensajes')}
                        >
                            Leer
                        </CustomButtonOval>
                    </span>
                </div>
            </div>

            <div className="p-4 flex flex-wrap max-w-[75%] ml-[19%] max-md:max-w-full max-md:ml-0">
                {servicios.map((s, index) => (
                    <div key={index} className="relative flex flex-col w-[48%] p-2 mt-4 rounded mr-4 max-md:w-full bg-o2o-gris-calido">
                        {/* Pestaña del estatus */}
                        <div className={`absolute top-0 left-0 ${s.estatus_id==5 ? 'bg-blue-500':(s.estatus_id==6 ? 'bg-yellow-500':'bg-green-500')} text-white px-1  rounded-t-md`}>
                            {s.estatus?.nombre}
                        </div>

                        <div className="flex justify-between items-center mb-2 mt-5">
                            <div className="flex items-center">
                                <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full overflow-hidden mr-4">
                                    <img
                                        loading="lazy"
                                        src={s.paciente?.perfilImg}
                                        className="max-w-full max-h-full"
                                        alt="User"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <p className="font-bold">{s.administrador?.nombre + ' ' + s.administrador?.apellidos}</p>
                                </div>
                            </div>
                            <CustomButtonOval
                                className="bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm"
                                onClick={() => navigate('/servicios')}
                            >
                                {'Ver solicitud'}
                            </CustomButtonOval>
                        </div>
                        <div className="w-full border-t border-o2o-gris-hover border-solid mt-1 mb-3"></div>
                        <div className="flex justify-between items-center">
                            <span>Administra al paciente:</span>
                            <CustomButtonOval
                                className="bg-o2o-gris hover:bg-o2o-aqua-hover text-sm"
                                onClick={() => navigate("/paciente-solicitud/" + s.paciente_id + '/' + s.id)}
                            >
                                {'Ver perfil del paciente'}
                            </CustomButtonOval>
                        </div>
                        <p className="mt-1 text-sm font-bold">{s.paciente?.nombre + ' ' + s.paciente?.apellidos}</p>
                        <p className="mt-1 text-sm"><span className="font-bold">Diagnóstico: </span>{s.paciente?.diagnosticoM}</p>
                        <p className="mt-1 text-sm"><span className="font-bold">Cuidados: </span>
                            {s.paciente?.cuidados.map((c, index) => (
                                <span key={index}>{c.nombre}, </span>
                            ))}  {s.paciente?.necesidades?.turno_nombre}
                        </p>
                        <p className="mt-1 text-sm"><span className="font-bold">Periodo: </span>{s.paciente?.fecha_inicio} al {s.paciente?.fecha_fin}</p>
                    </div>
                ))}


                <div className="flex flex-col w-full mt-10">
                    <div className="w-[106px] h-[106px] flex rounded-full overflow-hidden mb-2">
                        <img
                            loading="lazy"
                            src={authUser.perfilImg || "/img/user.png"}
                            className="max-w-full max-h-full"
                            alt="User"
                        />
                    </div>
                    <p className="font-bold text-base">{authUser.nombre}</p>
                    <p className="text-base">Cédula {authUser.acreditaciones.cedula_profecional}</p>
                </div>

                <div className="flex flex-col w-[48%] p-2 mt-4 rounded mr-4 max-md:w-full">
                    <h3 className="text-base">Datos personales</h3>
                    <div className="w-full border-t border-black border-solid mt-1 mb-3"></div>
                    <p className="mt-1 text-sm"><span className="font-bold">Edad:</span> {authUser.edad} años</p>
                    <p className="mt-1 text-sm"><span className="font-bold">Email:</span> {authUser.email}</p>
                    <p className="mt-1 text-sm"><span className="font-bold">Móvil:</span> {authUser.movil}</p>
                    <p className="mt-1 text-sm"><span className="font-bold">Telefono:</span> {authUser.telefono || 'N/A'}</p>
                    <p className="mt-1 text-sm"><span className="font-bold">Direccion:</span> {authUser.calle} Interior {authUser.numeroInterior || 'S/N'} Exterior {authUser.numeroExterior} {authUser.colonia} CP {authUser.cp}</p>
                    <p className="mt-1 text-sm">{authUser.municipio_nombre}, {authUser.estado_nombre}, {authUser.pais_nombre}</p>
                </div>

                <div className="flex flex-col w-[48%] p-2 mt-4 rounded mr-4 max-md:w-full">
                    <h3 className="text-base">Resumen</h3>
                    <div className="w-full border-t border-black border-solid mt-1 mb-3"></div>
                    <p className="mt-1 text-sm">{authUser.descripcionPersonal}</p>
                </div>

                <div className="flex flex-col w-[48%] p-2 mt-4 rounded mr-4 max-md:w-full">
                    <h3 className="text-base">Estudios & acreditaciones</h3>
                    <div className="w-full border-t border-black border-solid mt-1 mb-3">
                        <div className="flex justify-between">
                            <div>
                                <p className="font-bold mt-1 text-sm">Nivel:  {authUser.acreditaciones.estudios}</p>
                                <p className="font-bold mt-1 text-sm">Institución educativa: {authUser.acreditaciones.casa_estudios}</p>
                                <p className="font-bold mt-1 text-sm">Especialidades: {authUser.acreditaciones.formacion_academica && authUser.acreditaciones.formacion_academica.map((e, index) => (
                                        <button
                                            type="button"
                                            key={index}
                                            className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                        >
                                            {e.especialidad_nombre}
                                        </button>
                                    ))}</p>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-[48%] p-2 mt-4 rounded mr-4 max-md:w-full">
                    <h3 className="text-base">Turnos & disponibilidades</h3>
                    <div className="w-full border-t border-black border-solid mt-1 mb-3">
                        {turnos.map((t, index) => (
                            <div className="flex flex-row space-x-4" key={t.id}>
                                <div>
                                    {t.diasCollect.map((d, index) => (
                                        <span key={d.id}>
                                            {d.dia + ', '}
                                        </span>
                                    ))}
                                </div>
                                <div>{t.tiempo.nombre}</div>
                                <div>{t.turno.nombre}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResumenEnfermero;