import React from 'react'
import { getEstrellas } from '../../../helpers/recursos';
import { useSelector } from 'react-redux';

const CardsSatisfaccion = () => {
    const testimoniales = useSelector((state) => state.configPublic.calificables_plataforma || []);
    return (
        <>
        <div className="flex flex-wrap content-start self-stretch font mb-14">
            {testimoniales.map((data,index) => {
                return (
                <div className="w-full sm:w-1/3 p-1" key={index}>
                    <div className="flex flex-col grow p-5 w-full rounded-xl shadow-sm bg-stone-100 max-md:mt-3 h-[100%] ">
                        <div className="text-2xl font-bold text-black ">
                        {data?.nombre}
                        </div>
                        <div className="text-base leading-6 text-black">
                        {data?.descripcion}
                        </div>
                        <div className="flex flex-col justify-center px-9 py-1.5 w-full bg-orange-200 rounded-2xl max-md:px-5 mt-auto">
                            <div className="flex gap-3 pr-20 max-md:pr-5">
                            {getEstrellas(data?.promedio)} 
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
        </div>
        </>
    )
}

export default CardsSatisfaccion;