import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import { imgUrl } from "../../../helpers/recursosurl";
import { getAuthUser, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { favoritos } from "../../../helpers/configRoutes/backend_helper";
import Paginacion from "../../../components/Paginacion";
import ConfirmacionSolicitudModal from "../historial_servicios/ConfirmacionSolicitudModal";
import { useSelector } from "react-redux";

const Resultados = ({ search, paciente, enfermeros, setItem, filters, setFilters, meta }) => {
    const [deviceSessionId, setDeviceSessionId] = useState('');
    const [modal, setModal] = useState({ show: false, id_enfermero: null, paciente_id: null, loading: false });
    const config = useSelector((state)=>state.configZendesk.zendeskConfig);

    useEffect(() => {
        // Asegúrate de que Openpay está cargado
        if (window.OpenPay) {
            // Configurar OpenPay

            window.OpenPay.setId('m2cgajg1xoy21gyd4ptw');
            window.OpenPay.setApiKey('pk_c5d6c206cebd4e0eb4867df92d8961f4');
            window.OpenPay.setSandboxMode(true); // Cambiar a false en producción

            // Generar device_session_id
            const sessionId = window.OpenPay.deviceData.setup();
            setDeviceSessionId(sessionId);
        }
    }, []);

    const navigate = useNavigate()
    const usuario = getAuthUser()
    const [authUser, setAuthUser] = useState(getAuthUser())

    const sendFavorito = async (id) => {
        const response = await favoritos({ id_enfermero: id });
        if (response.status) {
            let storedData = localStorage.getItem('authUser');
            if (storedData) {
                let userData = JSON.parse(storedData);
                userData.user = response.administrador
                localStorage.setItem('authUser', JSON.stringify(userData));
                setAuthUser(getAuthUser())
            }
        }
    }

    const solicitudServicio = async (id_enfermero) => {
        setModal({ ...modal, loading: true })
        const response = await serviciosResource('post', { enfermero_id: id_enfermero, paciente_id: filters.paciente_id, device_session_id: deviceSessionId });
        if (response.status) {
            navigate('/servicios')
            setModal({ ...modal, show: false, loading: false })

        } else {
            if (response.action == 'add_payment') {
                navigate('/construccion')
            }
        }
        setModal({ ...modal, show: false, loading: false })
    }

    return (
        <>
            <div className="p-2">
                <h1 className="text-2xl font-bold mb-4">
                    Tenemos a los enfermeros ideales para {paciente?.nombre}
                </h1>
                <div className="bg-white p-6 border rounded-xl mb-4">
                    <h2 className="text-xl font-semibold mb-2">
                        Los siguientes perfiles han sido cuidadosamente seleccionados para atender las necesidades específicas de tu paciente.
                    </h2>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-1/2 p-2">
                        <p>
                            <b>Después de aceptar la solicitud de servicio, en un lapso máximo de 24 horas recibirás la confirmación</b> de alguno de ellos para ofrecerte el servicio de cuidados enfermeros.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2 p-2">
                        <p>
                            <b>Recibirás un SMS en tu móvil,</b> además de un mensaje en tu perfil de One 2 One (pestaña «historial de servicios {'>'} solicitudes enviadas»). Sugerimos estar atento de tu dispositivo o del sitio web.
                        </p>
                    </div>
                </div>
                <div className='w-full border-t border-o2o-gris-arena border-solid mb-3'></div>
                <div className="flex flex-col mt-4 p-2 bg-nefritas rounded-lg">
                    <p className="font-bold mb-2">Mientras tanto puedes:</p>
                    <ul className="list-disc pl-5">
                        <li>Ver el resumen del perfil de nuestros profesionistas para conocerlos.</li>
                        <li>Filtrar tu búsqueda para mejorar las características del perfil que te atenderá.</li>
                    </ul>
                </div>
                <div className='w-full border-t border-o2o-gris-arena border-solid mb-3'></div>
                <div className="flex flex-wrap items-center justify-between mb-1">
                    <h3 className="text-2xl font-bold">Resultados de la búsqueda</h3>
                </div>
                <div className='w-full border-t border-o2o-gris-arena border-solid mb-3'></div>

                <div className="container mx-auto p-0">
                    <div className="flex flex-wrap -mx-2">
                        {enfermeros?.map((e, index) => (
                            <div className="bg-white rounded-lg shadow-sm p-4 w-full sm:w-1/2 lg:w-[32.4%] relative border border-gray-300 m-1 mb-5 flex flex-col" key={index}>
                                <div className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2 shadow-md">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EBE1DC" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.2727 18.0256C20.2727 15.3333 18.1605 13 15.6963 13C13.2321 13 12 14.9744 12 16.7692C12 22.1538 18.5126 26.1026 20.2727 27C22.0329 26.1026 28.5455 22.1538 28.5455 16.7692C28.5455 14.9744 27.3133 13 24.8491 13C22.3849 13 20.2727 15.3333 20.2727 18.0256Z" fill={`${authUser.favoritos?.filter(res => res?.enfermero_id === e?.id).length > 0 ? '#FF0041' : '#EBE1DC'}`} />
                                    </svg>
                                </div>
                                <img src={e.perfilImg || 'https://placehold.co/400x300'} className="w-full rounded-lg" />
                                <div className="flex justify-between items-center mt-2">
                                    <h3 className="text-xl font-bold">{e.nombre} {e.apellidos}</h3>
                                    <span className="text-gray-600 flex items-center">
                                        <svg className="w-6 h-6 text-o2o-secondary-mostaza ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                        </svg>
                                        {e.enfermero.calificacion}
                                    </span>
                                </div>
                                <p className="text-gray-600">{e.edad} años</p>
                                <p className="mt-2 font-semibold">{e.acreditaciones?.estudios_nombre} en enfermería</p>
                                <p className="text-gray-600">Cédula: {e.acreditaciones?.cedula_profecional || 'N/A'}</p>
                                <p className="mt-2 font-semibold">Con especialidad en:</p>
                                <div className="flex flex-col space-y-2 mt-1">
                                    {e.acreditaciones?.formacion_especialidades?.map((esp, index) => (
                                        <CustomButtonOval
                                            key={index}
                                            type={'success'}
                                            className='bg-o2o-gris-arena text-sm w-full'
                                        >
                                            {esp.especialidad_nombre},
                                        </CustomButtonOval>
                                    ))}
                                </div>
                                <div className='w-full border-t border-o2o-gris-arena border-solid mb-3 mt-3'></div>
                                <p className="mt-2 text-gray-700">
                                    {e.sexo_id == 2 ? 'Enfermera especializada en: ' : 'Enfermero especializado en: '}
                                    {e.acreditaciones?.servicios?.map((s, index) => (
                                        <span key={index}>
                                            {` ${s.nombre}${index === e.acreditaciones.servicios.length - 1 ? '.' : ','}`}
                                        </span>
                                    ))}

                                </p>
                                <div className='w-full border-t border-o2o-gris-arena border-solid mb-3 mt-3'></div>
                                <div className="mt-auto flex justify-between items-center">
                                    <CustomButtonOval
                                        type={'success'}
                                        onClick={() => navigate(`/enfermero-detallado/${e.id}/${filters.paciente_id}`)}
                                        className='text-sm bg-o2o-aqua hover:bg-o2o-aqua-hover w-full lg:w-[90%]'
                                    >
                                        Ver perfil detallado
                                    </CustomButtonOval>
                                </div>
                                <div className="mt-auto flex justify-between items-center">
                                    {console.log(e, 'enfermerors')}
                                    <CustomButtonOval
                                        type={'success'}
                                        onClick={() => setModal({ show: true, id_enfermero: e?.id, paciente_id: paciente.id })}
                                        className='text-sm bg-o2o-aqua hover:bg-o2o-aqua-hover w-full lg:w-[90%]'
                                    >
                                        Solicitar servicio
                                    </CustomButtonOval>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Paginacion items={enfermeros} meta={meta} setFilters={setFilters} filters={filters} />
                </div>

                <div className="mt-8">
                    <h2 className="text-xl font-bold mb-2">Somos una comunidad segura</h2>
                    <p className="mb-4 text-gray-700 text-base">Consulta nuestros protocolos</p>
                    <div className="flex flex-wrap space-x-4">
                        <CustomButtonOval
                            type={'success'}
                            onClick={()=>window.open(config.terminos_condiciones?.url, "_blank")}
                            className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover mb-2'
                        >
                            Términos y condiciones
                        </CustomButtonOval>
                        <CustomButtonOval
                            type={'success'}
                            onClick={()=>window.open(config.codigo_conducta?.url, "_blank")}
                            className=''
                        >
                            Codigo de conducta
                        </CustomButtonOval>
                    </div>
                </div>
            </div>
            {modal.show &&
                <ConfirmacionSolicitudModal modal={modal} setModal={setModal} solicitudServicio={solicitudServicio} />
            }

        </>
    )
}

export default Resultados