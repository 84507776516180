import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Asegúrate de importar useNavigate si lo necesitas
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import { useSelector } from "react-redux";
import { pacientesResource, serviciosResource } from '../../helpers/configRoutes/backend_helper'

const ResumenAdmin = ({ authUser }) => {
    const navigate = useNavigate();
    const sinLeer = useSelector((state) => state.mensajesContador.sin_leer);
    const [filters, setFilters] = useState({ perPage: 5, page: 1, home: true })


    const [servicios, setServicios] = useState([])

    useEffect(() => {
        const getServicio = async () => {
            const response = await serviciosResource('get', { params: filters });
            setServicios(response.servicios);
        }
        getServicio();

    }, [filters]);

    const [pacientes, setPacientes] = useState([])
    useEffect(() => {
        const getPacientes = async () => {
            const response = await pacientesResource('get', { params: filters });
            setPacientes(response.pacientes);
        }
        getPacientes();
    }, []);
    return (
        <>
            <div className="flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%] p-4">
                <div className="w-full mb-4">
                    <b className="block mb-2 text-xl">Esta es tu central de información.</b>
                    <p>Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                </div>
            </div>
            <div className="p-4 flex-wrap max-w-[75%] ml-[19%] max-md:max-w-full max-md:ml-0">
                <div className="flex items-center bg-o2o-gris-calido p-0 mt-4 rounded text-xl">
                    <span className="bg-o2o-morado text-white p-3 rounded text-xl">{sinLeer}</span>
                    <span className="ml-2 text-sm">Conversaciones sin leer</span>
                    <span className="ml-auto">
                        <CustomButtonOval
                            className="bg-o2o-gris hover:bg-o2o-aqua-hover text-sm"
                            onClick={() => navigate('/mensajes')}
                        >
                            Leer
                        </CustomButtonOval>
                    </span>
                </div>
            </div>
            <div className="p-4 flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%]">
                {servicios.map((s, index) => {
                    if (s.cita_activa?.can?.califica) {
                        return (
                            <div key={index} className="flex w-full md:w-[48%] items-center bg-o2o-gris-calido p-2 mt-4 rounded mr-4 shadow-md">
                                <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full overflow-hidden mr-4 bg-o2o-aqua mb-4">
                                    <img
                                        loading="lazy"
                                        src={s.enfermero.perfilImg}
                                        className="max-w-full max-h-full"
                                        alt="User"
                                    />
                                </div>
                                <div className="flex flex-col flex-grow text-sm">
                                    <b className="block mb-1">{s.enfermero.nombre}</b>
                                    <p>Especialidad en {s.enfermero.especialidad}</p>
                                    <p className="mt-2">Califica el primer día de tu servicio</p>
                                    <p>{s.fecha_servicio}</p>
                                </div>
                                <div className="flex justify-between mt-0">
                                    <CustomButtonOval
                                        isDisabled={!s.cita_activa?.can?.calificar}
                                        className="bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm"
                                        onClick={() => navigate('/servicios')}
                                    >
                                        Califica el servicio
                                    </CustomButtonOval>
                                </div>
                            </div>
                        )
                    }

                })}
                <div className="flex flex-col items-start mt-10 w-full">
                    <div className="w-[106px] h-[106px] flex rounded-full overflow-hidden mb-2">
                        <img
                            loading="lazy"
                            src={authUser.perfilImg || "/img/user.png"}
                            className="max-w-full max-h-full"
                            alt="User"
                        />
                    </div>
                    <p className="text-center font-bold text-base">{authUser.nombre}</p>
                </div>
                {servicios.map((s, index) => {
                    if (s.estatus_id == 5) {
                        return (
                            <div key={index} className="flex w-full items-center bg-o2o-gris-calido p-2 mt-4 rounded-xl shadow-md text-sm">
                                <div className="flex flex-col flex-grow">
                                    <p className="block mb-1"><span className="font-bold">Nombre del paciente:</span> {' '}{s.paciente?.nombre} {s.paciente?.apellidos}</p>
                                    <p className="block mb-1"><span className="font-bold">Especialidad solicitada:</span>{' '}{s.enfermero?.especialidad}</p>
                                    <p className="mt-2"><span className="font-bold">Fecha de servicio:</span> {' '}{s.fecha_inicial} hasta {s.fecha_fin}. {s.tiempo?.horas}. {s.turno.nombre}</p>
                                </div>
                                <div className="flex flex-col flex-grow">
                                    <p className="flex justify-end"><span className="font-bold">Cuidados Solicitados:</span></p>
                                    <div className="flex flex-wrap justify-end gap-3 mb-10">
                                        {s.paciente?.cuidados.map((data, index) => (
                                            <button
                                                type="button"
                                                key={index}
                                                className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                            >
                                                {data.nombre}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
                <div className="flex w-full items-center p-2 mt-4 rounded-xl text-sm">
                    <div className="flex flex-col flex-grow">
                        <p><span className="font-bold">Edad:</span>{' '}{authUser.edad} Años</p>
                        <p><span className="font-bold">Email:</span>{' '}{authUser.email}</p>
                        <p><span className="font-bold">Movil:</span>{' '}+52{authUser.movil}</p>
                        <p><span className="font-bold">Telefono:</span>{' '}+52{authUser.telefono}</p>
                        <p><span className="font-bold">Direccion:</span>{' '}{authUser.calle} {authUser.numeroExterior} {authUser.numeroInterior} CP {authUser.cp} {authUser.direccion.municipio.nombre} {authUser.direccion.estado.nombre}, {authUser.direccion.pais.nombre}</p>
                    </div>
                    <div className="flex flex-col flex-grow">
                    <p className="flex justify-end"><span className="font-bold">Intereses Personales:</span></p>
                        <div className="flex flex-wrap justify-end gap-3 mb-10">
                            {authUser.interesesPersonales.map((data, index) => (
                                <button
                                    type="button"
                                    key={index}
                                    className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                >
                                    {data.nombre}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full p-12 flex justify-start">
                    <CustomButtonOval
                        className="bg-o2o-gris hover:bg-o2o-aqua-hover text-sm"
                        onClick={() => navigate('/perfiles')}
                    >
                        Editar
                    </CustomButtonOval>
                </div>
            </div>
            <div className="p-4 flex flex-wrap max-w-full md:max-w-[75%] ml-4 md:ml-[19%]">
                {pacientes.map((p, index) => {
                    if (p.can?.sendSolicitud) {
                        return (
                            <div key={index} className="relative flex w-full md:w-[45%] mr-3 items-center p-2 mt-4 rounded-xl border-2 border-o2o-gris-arena">
                                <div className="flex w-full items-center p-2 text-sm">
                                    {/* Imagen a la izquierda */}
                                    <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full overflow-hidden mr-4">
                                        <img
                                            loading="lazy"
                                            src={p.perfilImg}
                                            className="max-w-full max-h-full"
                                            alt="User"
                                        />
                                    </div>

                                    {/* Nombre a la derecha */}
                                    <div className="flex-grow">
                                        <p className="font-bold"><span className="font-bold">Nombre:</span> {p.nombre} {p.apellidos}</p>
                                        <p><span className="font-bold">Edad:</span> {p.edad} Años</p>
                                        <p><span className="font-bold">Requiere:</span></p>
                                        <p className="mt-2">
                                        <span className="font-bold">Dias:{' '}</span>
                                            {p.necesidad.dias.map((data, index) => (
                                                <span key={index}>
                                                    {data.dia + ', '}
                                                </span>
                                            ))}
                                        </p>
                                        <p><span className="font-bold">Fecha de servicio:{' '}</span> {p.necesidad.fecha_inicio} hasta {p.necesidad.fecha_fin}</p>
                                        <p className="mb-3"><span className="font-bold">Especialidad:{' '}</span> {p.necesidad.especialidad.nombre}.</p>

                                        {/* Cuidados */}
                                        <div className="flex flex-wrap justify-start gap-1 mb-10">
                                            {p.necesidad.cuidados.map((data, index) => (
                                                <button
                                                    type="button"
                                                    key={index}
                                                    className="justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover bg-o2o-gris-hover"
                                                >
                                                    {data.nombre}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Botón de búsqueda en la esquina superior derecha */}
                                    <CustomButtonOval
                                        type={'success'}
                                        onClick={() => navigate(`/buscar-enfermero/paciente/${p.id}`)}
                                        className="absolute top-2 right-2 bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover"
                                    >
                                        Busqueda de enfermero
                                    </CustomButtonOval>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>

        </>
    )
}

export default ResumenAdmin