import React from "react";
import { useSelector } from "react-redux";

const SliderHome = () => {
    const testimoniales = useSelector(
        (state) => state.configPublic.testimoniales_plataforma || []
    );

    const prevSlider = () => {
        const bodySlider = document.getElementById("bodySlider");
        bodySlider.scrollBy({ left: -336, behavior: "smooth" });
    };

    const nextSlider = () => {
        const bodySlider = document.getElementById("bodySlider");
        bodySlider.scrollBy({ left: 336, behavior: "smooth" });
    };

    return (
        <div className='flex flex-col bottom-0 absolute'>
            <div className=' overflow-hidden w-screen max-w-screen items-stretch' id="bodySlider">
                <div className='inline-flex relative space-x-2 py-6 overflow-hidden h-full' id="bodySlider2">
                    {testimoniales.length == 2 &&
                        <div className='flex flex-col rounded-xl  relative w-[336px] h-[235px] p-2' ></div>
                    }
                    {testimoniales.map((item, index) => (
                        <div className='flex flex-col rounded-xl bg-o2o-gris-arena relative w-[336px] h-[235px] p-2' key={index}>
                            {/* Imagen del perfil */}
                            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                                <img
                                    src={item.usuario?.perfilImg || "/img/default-profile.png"}
                                    alt="profile"
                                    className="w-16 h-16 rounded-full border-2 border-white shadow"
                                />
                            </div>
                            <div className="flex flex-col mt-8">
                                {/* Contenedor superior: Nombre y Estrellas */}
                                <div className="flex flex-row justify-between items-center">
                                    {/* Nombre alineado a la izquierda */}
                                    <p className="font-bold text-left">{item.usuario?.informacion?.nombre || "Usuario"}</p>

                                    {/* Estrellas alineadas a la derecha */}
                                    <div className="flex flex-row rounded-[18px] px-2 space-x-1 bg-white">
                                        {new Array(item.calificacion || 0).fill("").map((_, i) => (
                                            <img
                                                src="/icons/start-fill.svg"
                                                alt="star"
                                                key={i}
                                                className="w-4 h-4"
                                            />
                                        ))}
                                    </div>
                                </div>
                                {/* Comentario debajo */}
                                <p className="line-clamp-6 text-sm text-gray-600 mt-4">
                                    {item.testimonial || "Sin comentario disponible."}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Controles del slider */}
            <div className="flex flex-row items-center justify-center space-x-4 mt-4">
                <button
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-o2o-gris-arena shadow cursor-pointer"
                    onClick={prevSlider}
                >
                    <span className="material-symbols-outlined text-[18px]">arrow_back_ios</span>
                </button>
                <button
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-o2o-gris-arena shadow cursor-pointer"
                    onClick={nextSlider}
                >
                    <span className="material-symbols-outlined text-[18px]">arrow_forward_ios</span>
                </button>
            </div>
        </div>
    );
};

export default SliderHome;
