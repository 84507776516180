import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { blogsInfo } from '../../../helpers/recursos'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { entradasBlog } from '../../../helpers/configRoutes/backend_helper'
import Buscador from '../home/Buscador'
import Buscardor2 from '../home/Buscardor2'

const Blog = () => {
  const navigate = useNavigate();
  const [entradas, setEntradas] = useState([])

  useEffect(() => {
    const getEntradas = async () => {
      const response = await entradasBlog();
      setEntradas(response.entradas)
    }
    getEntradas()
  }, []);

  return (
    <>
      <Buscardor2 blog={true}/>
      <div className="flex flex-col p-12 rounded-2xl  max-md:px-5 mb-20">
        <div className="flex gap-2.5 text-black max-md:flex-wrap">
          <div className="flex-auto text-3xl font-bold leading-9 max-md:max-w-full font">
            Últimas entradas del Blog
          </div>

        </div>
        <div className="mt-6 max-md:max-w-full font">
          <div className="flex flex-wrap">
            {entradas.map((data, index) => {
              return (
                <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full p-4" key={index}>
                  <div className="flex flex-col grow text-sm leading-4 text-black max-md:mt-9">
                    <img
                      loading="lazy"
                      srcSet={data?.image}
                      className="w-full aspect-[1.45]"
                    />
                    <div className="mt-8 text-2xl font-bold leading-8">
                      {data?.title}
                    </div>

                    <div className="justify-center self-start mt-5">
                      <CustomButtonOval
                        className={'bg-o2o-gris hover:bg-o2o-gris-hover'}
                        onClick={() => window.open(data.url, '_blank', 'noopener,noreferrer')}
                      >
                        Seguir leyendo
                      </CustomButtonOval>
                    </div>

                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center mt-10 font">
          <div className='w-1/3 text-center'>
            <CustomButtonOval
              className={'bg-o2o-aqua hover:bg-o2o-aqua-hover'}
              onClick={() => window.open('https://escueladeenfermeriangelopolis.zendesk.com/hc/es-419/sections/30453244288147-Blog-Cuidados-ONE2ONE', '_blank', 'noopener,noreferrer')}

            >
              Ver entradas anteriores +
            </CustomButtonOval>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center mt-10 bg-o2o-gris-arena rounded-xl font">
          <div className="w-2/3 text-center font-bold text-5xl mb-10 mt-5">
            Más sobre nosotros
          </div>
          <div className="w-2/3 text-center font-bold mb-5 flex flex-row items-center justify-center space-x-5">
            <CustomButtonOval
              className="bg-o2o-gris hover:bg-o2o-gris-hover px-6 py-3"
              onClick={() => navigate('/quienes-somos')}
            >
              ¿Quiénes somos?
            </CustomButtonOval>
            <CustomButtonOval
              className="bg-o2o-violeta hover:bg-o2o-violeta-hover px-6 py-3"
              onClick={() => window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')}
            >
              Centro de Atención
            </CustomButtonOval>
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog