import {
    SET_CONFIG,
} from "./actionTypes";

const initialState = {
    zendeskConfig: {}
};

const configZendesk = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIG:
            return {
                ...state,
                zendeskConfig: action.payload,
            };
        default:
            return state;
    }
};

export default configZendesk;
