"use client"
import React from 'react'
import Buscardor2 from '../home/Buscardor2';
import { levels, pricingHours, serviceCards } from '../../../helpers/recursos';
import RegistrateCliente from '../home/RegistrateCliente';
import CustomButtonOval from '../../../components/buttons/CustomButtonOval';

const ServiceCard = ({ src, alt, title, description }) => {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow self-stretch pb-20 text-center text-black max-md:mt-10">
        <img loading="lazy" src={src} alt={alt} className="self-center max-w-full aspect-square  w-[105px]" />
        <h3 className="mt-6 text-2xl font-bold whitespace-nowrap">{title}</h3>
        <p className="mt-3 text-base leading-6">{description}</p>
      </div>
    </div>
  );
};

const LevelCard = ({ level, description, experienceYears }) => (
  <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow px-8 py-5 w-full text-black rounded-xl bg-stone-200 max-md:px-5 max-md:mt-5">
      <div className="text-2xl font-bold whitespace-nowrap">
        {level}
      </div>
      <div className="mt-5 text-base leading-6">
        {description}
        <br />{experienceYears}
      </div>
    </div>
  </div>
);

const PricingCard = ({ level, prices }) => (

  <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow text-2xl font-bold text-black max-md:mt-10">
      <div className="justify-center px-2.5 py-1 text-sm leading-4 text-center bg-cyan-400 rounded-md">
        {level}
      </div>
      {prices.map((price, index) => (
        <React.Fragment key={index}>
          <div className="mt-5">${price.amount}</div>
          <div className="text-xs leading-3">{price.time}</div>
        </React.Fragment>
      ))}
    </div>
  </div>
);

const ServiceLevel = ({ level, description }) => (
  <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full font">
    <div className="flex flex-col grow p-6 w-full text-black rounded-xl bg-stone-200 max-md:px-5 max-md:mt-5">
      <h3 className="text-2xl font-bold leading-8 ">
        Nivel {level}
      </h3>
      <h3 className="text-2xl font-normal leading-8 ">
        <span>Hasta {description.services} servicios en un lapso de 30 días</span>
      </h3>
      <p className="mt-5 text-sm leading-5">
        <ol className="list-decimal pl-5 space-y-2 ">
          <li>{description.services} servicios individuales en turnos de hasta 12 horas.</li>
          <li>Los servicios pueden —o no— ser simultáneos.</li>
          <li>Los proveedores pueden usar el uniforme de la institución y el ID de One to One Nurses.</li>
        </ol>
      </p>
    </div>
  </div>
);


const CostosMenbresias = () => {

  const serviceLevels = [
    { level: 1, description: { services: 5 } },
    { level: 2, description: { services: 10 } },
    { level: 3, description: { services: 15 } },
    { level: 4, description: { services: 20 } },
  ];

  return (
    <>
      <Buscardor2 />

      <div className="flex flex-col items-center px-5 mb-10 font">
        <section className="self-stretch w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {serviceCards.map((card, index) => (
              <ServiceCard key={index} {...card} />
            ))}
          </div>
        </section>
        <div className="text-5xl font-bold leading-10 text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
          Tabulador de precios
        </div>
        <p className="mt-5 text-base leading-6 text-center text-black w-[692px] max-md:max-w-full">
          Homologamos nuestros servicios y cuotas basados en los estándares del mercado, para que no tengas que pagar más de lo necesario.
        </p>
      </div>

      <div className="flex flex-col font">

        <div className="flex-wrap content-start px-5 w-full shadow-sm max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {levels.map(level => (
              <LevelCard key={level.level} {...level} />
            ))}
          </div>
        </div>
        {pricingHours.map(entry => (
          <div className="flex-wrap content-start p-8 mt-5 w-full rounded-xl shadow-sm bg-stone-100 max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                <div className="text-3xl font-bold leading-9 text-black whitespace-nowrap max-md:mt-10">
                  {entry?.hours}
                </div>
              </div>
              {entry.levels.map(level => (
                <PricingCard key={level.level} {...level} />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-wrap gap-5 justify-between content-start mt-5 font mb-10">

        <div className="justify-center items-center self-start text-center w-full md:w-1/12">
          <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full shadow-md">
            !
          </button>
        </div>
        <div className="flex-auto text-sm leading-5 text-black w-full md:w-6/12">
          Mentir sobre el diagnóstico del paciente para obtener cuidados
          enfermeros de niveles más accesibles, no sólo pone en riesgo la salud
          del paciente; sino infringe los términos del contrato con One 2 One
          Nurses.
        </div>
        <div className="justify-center self-start text-center w-full md:w-4/12 ">
          <CustomButtonOval
            className="bg-o2o-gris hover:bg-o2o-gris-hover"
            onClick={() => window.open("https://escueladeenfermeriangelopolis1722449090.zendesk.com/hc/es-419/articles/32084385646739-T%C3%89RMINOS-Y-CONDICIONES-DE-USO-DEL-SITIO", "_blank")}
          >
            Leer los Términos y Condiciones
          </CustomButtonOval>
        </div>
      </div>

      <div className='flex flex-col items-center px-5 mb-10'>
        <h1 className="text-5xl font-bold leading-10 text-center text-black max-md:max-w-full max-md:text-4xl">
          Modelos empresariales
        </h1>
        <p className="mt-5 text-base leading-6 text-center text-black w-[692px] max-md:max-w-full mb-5">
          One to One Nurses puede satisfacer las necesidades de instituciones y personas morales, para quienes ofrecemos modelos de suscripción con diferentes alcances y beneficios.
        </p>
        <button className="justify-center">
          <CustomButtonOval
            onClick={() => window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')}
            className='bg-o2o-aqua hover:bg-o2o-aqua-hover'
          >
            Chatea con un ejecutivo
          </CustomButtonOval>
        </button>
      </div>

      <div className='flex flex-col items-center px-5 mb-10'>
        <div className="flex-wrap content-start self-stretch mt-14 w-full shadow-sm max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {serviceLevels.map((serviceLevel) => (
              <ServiceLevel key={serviceLevel.level} {...serviceLevel} />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h2 className="text-5xl font-bold leading-10 text-center text-black max-md:max-w-full max-md:text-4xl">
          ¿Aún tienes dudas?
        </h2>
        <div className="text-base leading-6 text-center text-black max-md:max-w-full mt-[12px] mb-5">
          Consulta el Centro de Atención, donde respondemos preguntas frecuentes y encontrarás <br /> los reglamentos de conducta y contratos de servicio.
        </div>
        <CustomButtonOval
          onClick={() => window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')}
          className="bg-o2o-morado-calido hover:bg-o2o-aqua-hover px-6 py-3 mt-5"
        >
          Ir al Centro de Atención
        </CustomButtonOval>
      </div>


      <RegistrateCliente />
    </>
  )
}

export default CostosMenbresias;