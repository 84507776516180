import React, { useState } from 'react'
import Buscador from '../home/Buscador'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import CodigoDeContucta from './CodigoDeContucta'
import { useNavigate } from 'react-router-dom'
import ContratoServicios from './ContratoServicios'

const DocumentacionInformacion = () => {

    const [opcion, setOpcion] = useState(3);
    const navigate = useNavigate();

    const clientesPacientes = [
        {
            label: 'Contrato de Prestación de Servicios',
            opcion: 1
        },
        {
            label: 'Términos y condiciones',
            opcion: 2
        },
        {
            label: 'Código de conducta',
            opcion: 3
        },
        
    ];

    const enfermeros = [
        {
            label: 'Contrato de Prestación de Servicios',
            opcion: 4
        },
        {
            label: 'Términos y condiciones',
            opcion: 5
        },
        {
            label: 'Código de conducta',
            opcion: 6
        },
        
    ];


    const getViewOption = () => {
        
        switch (opcion) {
            case 1:
                return <ContratoServicios/>;
            case 3:
                return <CodigoDeContucta/>; 
                    
            default:
                return <></>;
                break;
        }
    }

    return (
        <>
        <Buscador/>
        <div className='flex flex-wrap mt-10 mr-[60px] ml-[60px]'>
            
            <div className='w-2/12'>
                <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-xs hover:bg-o2o-gris-calido-hover mb-[32px]" onClick={() => window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')}>
                    <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                    >
                        <path
                            d="M5.80078 1L1.00078 5L5.80078 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Regresar a Central de Atención
                </CustomButtonOval>

                <div className="mt-8 w-full text-2xl font-bold leading-8">
                    Documentación e información legal
                </div>
                <div className="mt-8 w-full text-base font-bold leading-6">
                    Clientes y pacientes
                </div>
                {clientesPacientes.map((data,index) => {
                    return (
                        <button 
                            className={`mt-1.5 w-full leading-5 text-start hover:font-bol  ${opcion === data?.opcion ? 'text-violet-700 font-bold' : 'hover:text-violet-700 hover:font-bold'}`}
                            onClick={() => setOpcion(data?.opcion)}
                        >
                            {data?.label}
                        </button>
                    )
                })}
                <div className="mt-8 w-full text-base font-bold leading-6 ">
                    Enfermeros
                </div>
                {enfermeros.map((data,index) => {
                    return (
                        <button 
                            className="mt-1.5 w-full leading-5 text-start hover:font-bol hover:text-violet-700 hover:font-bold"
                            onClick={() => setOpcion(data?.opcion)}
                        >
                            {data?.label}
                        </button>
                    )
                })}
                
                <button className="mt-8 w-full hover:text-violet-700 text-start hover:font-bold">Aviso de Privacidad</button>
            </div>
            
            <div className='w-10/12 p-5'>
               {getViewOption()}
            </div>
        </div>
        </>
    )
}

export default DocumentacionInformacion