import { useNavigate } from 'react-router-dom'
import CustomButtonSquare from '../../../components/buttons/CustomButtonSquare'
import React from 'react'
import { reset_usuario } from '../../../store/users/registro/action'
import { useDispatch } from 'react-redux'
import { warningToast } from '../../../helpers/Toast'

const RegistrateCliente = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate();
    const goRegistro = (action) => {
        dispatch(reset_usuario())
        navigate(`/registro/${action}`)
    }

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between mb-10 w-full max-w-o2o">
                <div className="flex w-full md:w-1/2 md:mr-1 mb-4 md:mb-0">
                    <CustomButtonSquare
                        className='bg-o2o-gris-arena hover:bg-o2o-gris-hover w-full'
                        onClick={() => warningToast(`Estamos trabajando para ofrecerte esta funcionalidad muy pronto. ¡Gracias por tu paciencia!`)}>
                        <div className='px-5 py-5 md:px-10 md:py-10'>
                            <p className='text-3xl font-bold mb-[18px] md:pr-[15rem]'>Regístrate como cliente</p>
                            <p className='hidden md:block'>
                                <strong>Crea tu perfil de cliente o paciente</strong> y conecta con el enfermero ideal para ti.
                            </p>
                        </div>
                    </CustomButtonSquare>
                </div>
                <div className="flex w-full md:w-1/2 md:ml-1">
                    <CustomButtonSquare
                        className='bg-o2o-aqua hover:bg-o2o-aqua-hover w-full'
                        onClick={() => goRegistro(2)}
                    >
                        <div className='px-5 py-5 md:px-10 md:py-10'>
                            <p className='text-3xl font-bold mb-[18px] md:pr-[13.3rem]'>Regístrate &nbsp;&nbsp;&nbsp; como enfermer@</p>
                            <p className='hidden md:block'>
                                <strong>Crea tu perfil profesional.</strong> Busca tu autonomía laboral y elige tus propios horarios.
                            </p>
                        </div>
                    </CustomButtonSquare>
                </div>
            </div>
        </>
    )
}

export default RegistrateCliente